/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `userdataform` - userdataform * `landingpage` - landingpage * `legalpage` - legalpage * `problem` - problem * `problemsource` - problemsource * `problemsourcelisting` - problemsourcelisting * `digitaltextbook` - digitaltextbook * `digitaltextbooksection` - digitaltextbooksection * `cheatsheet` - cheatsheet * `quiz` - quiz * `subject` - subject * `course` - course * `coursemodule` - coursemodule * `product` - product * `frontofficepage` - frontofficepage * `containerpage` - containerpage * `maturadownloaderpage` - maturadownloaderpage * `highereducationinstitution` - highereducationinstitution * `universityinstitution` - universityinstitution * `universitystudyprogram` - universitystudyprogram
 * @export
 * @enum {string}
 */

export const CacheKeyBaseEnum = {
    Userdataform: 'userdataform',
    Landingpage: 'landingpage',
    Legalpage: 'legalpage',
    Problem: 'problem',
    Problemsource: 'problemsource',
    Problemsourcelisting: 'problemsourcelisting',
    Digitaltextbook: 'digitaltextbook',
    Digitaltextbooksection: 'digitaltextbooksection',
    Cheatsheet: 'cheatsheet',
    Quiz: 'quiz',
    Subject: 'subject',
    Course: 'course',
    Coursemodule: 'coursemodule',
    Product: 'product',
    Frontofficepage: 'frontofficepage',
    Containerpage: 'containerpage',
    Maturadownloaderpage: 'maturadownloaderpage',
    Highereducationinstitution: 'highereducationinstitution',
    Universityinstitution: 'universityinstitution',
    Universitystudyprogram: 'universitystudyprogram'
} as const;

export type CacheKeyBaseEnum = typeof CacheKeyBaseEnum[keyof typeof CacheKeyBaseEnum];



